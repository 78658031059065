
import {defineComponent, PropType} from 'vue';
import CardBlock from '@/components/CardBlock.vue';
import useDeck from '@/composeables/useDeck';
import { ServerCard } from '@/common/interfaces';
import useConstants from '@/composeables/useConstants';

export default defineComponent({
    name: 'DefaultCompetenceBlock',
    components: {CardBlock},
    props: {
        index: {type: Number, required: true},
        cards: {type: Object as PropType<{[p: string]: ServerCard}>, required: true},
        title: {type: String, required: true},
        icon: {type: String, required: true},
        color: {type: String, required: true}
    },
    setup() {
        return {
            deck: useDeck().cards,
            cardDelay: useConstants().getCardDelay
        }
    }
})
