const groupDelay = 350;
const cardDelay = 150;

export default () => {
    return {
        getGroupDelay: (groupIndex: number) => {
            return {transitionDelay: `${groupDelay * groupIndex}ms`};
        },
        getCardDelay: (groupIndex: number, cardIndex: number) => {
            return {transitionDelay: `${(groupDelay * groupIndex) + (cardDelay * cardIndex)}ms`};
        }
    }
}

