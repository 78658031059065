
import {defineComponent, ref} from 'vue';
import PreviousPageButton from '@/components/navigation/PreviousPageButton.vue';
import DefaultCompetenceBlock from '@/components/DefaultCompetenceBlock.vue';
import useGroups, {CompetenceGroup, CompetenceSubgroup, CompetenceVariations, EducationType} from '@/composeables/useGroups';
import useCompetences from '@/composeables/useCompetences';
import useConstants from '@/composeables/useConstants';

export default defineComponent({
    name: 'CompetencePage',
    components: {DefaultCompetenceBlock, PreviousPageButton},
    props: {
        educationTypeId: {type: Number, required: true},
        groupId: {type: Number, required: true},
        variationId: {type: Number, required: true}
    },
    setup(props) {
        useCompetences().load();

        const selectedCompetenceId = ref(0);
        const educationType = ref<EducationType>();
        const competenceGroup = ref<CompetenceGroup>();
        const competenceSubgroup = ref<CompetenceSubgroup>();
        const competenceVariations = ref<CompetenceVariations>();

        useGroups().load().then((groups) => {
            if (!groups) {
                throw 'Failed to load group data.';
            }

            educationType.value = groups.educationTypes[props.educationTypeId];
            competenceGroup.value = groups.competenceGroups[props.groupId];
            competenceVariations.value = groups.competenceVariations[props.variationId];
            competenceSubgroup.value = groups.competenceSubgroups[competenceVariations.value?.parentId];
            // Set initial competence ID to the first ID that is found
            selectedCompetenceId.value = Number(Object.keys(competenceVariations.value.competences)[0]);
        });

        return {
            selectedCompetenceId,
            educationType,
            competenceGroup,
            competenceSubgroup,
            competenceVariations,
            competences: useCompetences().competences,
            setActiveCompetence(id: number|string) {
                selectedCompetenceId.value = Number(id);
            },
            groupDelay: useConstants().getGroupDelay
        }
    }
})
