import FetchHelper from '@/helpers/FetchHelper';
import {WpTerm} from '@/common/interfaces';
import {ref, computed} from 'vue';
import useDeck from '@/composeables/useDeck';

export interface GroupEducationType {term: WpTerm; children: {[key: string]: GroupA};}
export interface GroupA {term: WpTerm; children: {[key: string]: GroupB};}
export interface GroupB {term: WpTerm; children: {[key: string]: GroupC};}
export interface GroupC {term: WpTerm; children: number[];}

const groups = ref<{[key: string]: GroupEducationType}>({});
let promise: undefined| Promise<void>;

// function prune(group: GroupEducationType|GroupA|GroupB) {
//     for (const childId in group.children) {
//         if (shouldBePruned(group.children[childId])) {
//             delete group.children[childId];
//         }
//     }
//
//     return group;
// }
//
// function shouldBePruned(group: GroupEducationType|GroupA|GroupB|GroupC) {
//     if (Array.isArray(group.children)) {
//         const competenceIdsInDeck = useDeck().competenceIds.value;
//
//         for (const competenceId of group.children) {
//             if (competenceIdsInDeck.includes(competenceId)) {
//                 return false;
//             }
//         }
//
//         return true;
//     } else {
//         return Object.keys(group.children).length === 0;
//     }
// }

export default () => {
    return {
        myGroups: computed(() => groups.value),
        getPromise() {
            return promise;
        },
        load(force = false) {
            const body = new FormData();
            body.set('competenceIds', useDeck().competenceIds.value.join(','));

            if (!promise || force) {
                promise = FetchHelper.post<{[p: string]: GroupEducationType}>('wp-json/raow-api/groups-by-competence/', body).then((response) => {
                    groups.value = response;
                    promise = undefined;
                });
            }

            return promise;
        }
    }
}
