
import useDeck from '@/composeables/useDeck';
import useMyGroups from '@/composeables/useMyGroups';
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'RemoveCardModal',
    components: {},
    setup() {
        return {
            cardWord: (useDeck().selectedCards.value.length > 1 ? 'kaarten' : 'kaart'),
            selectedCards: useDeck().selectedCards,
            removeSelectedCards() {
                useDeck().removeSelectedCards();
                useMyGroups().load(true);
            },
            onCancel() {
                useDeck().selectCards([]);
            }
        };
    }
})
