
import {computed, defineComponent, ref} from 'vue';
import {Routes} from '@/router';
import PreviousPageButton from '@/components/navigation/PreviousPageButton.vue';
import useGroups from '@/composeables/useGroups';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import useConstants from '@/composeables/useConstants';

export default defineComponent({
    name: 'ChooseCompetencePage',
    components: {ProgressSpinner, PreviousPageButton},
    props: {
        educationTypeId: {type: Number, required: true},
        groupId: {type: Number, required: true}
    },
    setup(props) {
        useGroups().load();
        const subgroupElements = ref();

        const educationType = computed(() => useGroups().groups.value?.educationTypes[props.educationTypeId]);
        const competenceGroup = computed(() => useGroups().groups.value?.competenceGroups[props.groupId]);
        const subGroups = computed(() => useGroups().groups.value?.competenceSubgroups);
        const variations = computed(() => useGroups().groups.value?.competenceVariations);

        return {
            subgroupElements,
            subGroups,
            educationType,
            competenceGroup,
            variations,
            Routes,
            groupDelay: useConstants().getGroupDelay,
            cardDelay: useConstants().getCardDelay
        }
    }
})
