import XhrResource from '@/helpers/XhrResource';
import {ServerCard, WpTerm} from '@/common/interfaces';

export interface Competence {
    id: number;
    title: string;
    resultText: string;
    skillLevel: WpTerm|undefined;
    parentId: number;
    thoughtCards: {[key: string]: ServerCard};
    actionCards: {[key: string]: ServerCard};
}

const competences = new XhrResource<{[key: string]: Competence}>();

export default () => {
    return {
        competences: competences.get(),
        load() {
            return competences.load('wp-json/raow-api/competences/');
        }
    }
}
