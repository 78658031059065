
import {defineComponent, nextTick, ref} from 'vue';
import Vue3Html2pdf from 'vue3-html2pdf';
import MyCardsPage from '@/pages/MyCardsPage.vue';
import useDeck from '@/composeables/useDeck';

export default defineComponent({
    name: 'ExportPage',
    components: {MyCardsPage, Vue3Html2pdf},
    props: {},
    setup() {
        const pdfAction = ref<'download'|'mail'>('download');
        const html2Pdf = ref();
        const mailResult = ref({success: false, message: ''});
        //const formData = ref<FormData>();

        return {
            cards: useDeck().cards,
            html2Pdf,
            pdfAction,
            mailResult,
            downloadPDF() {
                pdfAction.value = 'download';
                nextTick(html2Pdf.value?.generatePdf)
            },
            // mailPDF(e: SubmitEvent & {target: HTMLFormElement}) {
            //     pdfAction.value = 'mail';
            //     formData.value = new FormData(e.target);
            //     nextTick(html2Pdf.value?.generatePdf);
            // },
            // beforeDownload(e: any) {
            //     if (pdfAction.value === 'mail') {
            //         // noinspection TypeScriptValidateJSTypes
            //         e.html2pdf().from(e.pdfContent).toPdf().output('blob').then((pdf: Blob) => {
            //             if (formData.value) {
            //                 formData.value.set('pdf', pdf);
            //                 FetchHelper.post('wp-json/raow-api/mail-pdf/', formData.value).then((response: {success: boolean, message: string}) => {
            //                     mailResult.value = Object.freeze(response);
            //                 });
            //             }
            //         });
            //     }
            // }
        }
    }
})
