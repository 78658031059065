
import {computed, defineComponent, PropType} from 'vue';
import useDeck from '@/composeables/useDeck';
import {LocalCard, ServerCard} from '@/common/interfaces';

export default defineComponent({
    name: 'CardBlock',
    props: {
        card: {type: Object as PropType<ServerCard>, required: true},
        storedData: {type: Object as PropType<LocalCard>, default: undefined},
        skillLevelLabel: {type: String, default: undefined},
        color: {type: String, required: true},
        isPdfExport: {type: Boolean, default: false}
    },
    setup(props) {
        let clicks = 0;
        const myCardsIcon = document.getElementById('my-cards-icon');

        const isInDeck = computed(() => {
            const cards = useDeck().cards.value;
            return (typeof cards === 'object' && props.card.uid in cards);
        });

        return {
            addOrRemoveCard(e: PointerEvent & {target: HTMLElement}) {
                if (isInDeck.value) {
                    useDeck().selectCards([props.card.uid]);
                } else {
                    if (myCardsIcon && e.target?.parentElement) {
                        clicks++;
                        const card = e.target.closest('.card');

                        if (card instanceof HTMLElement) {
                            const cloneCard = card.cloneNode(true) as HTMLElement;
                            const cloneCardButton = cloneCard.querySelector('button');
                            if (cloneCardButton) {
                                cloneCardButton.remove();
                            }

                            cloneCard.classList.add('clone');
                            cloneCard.style.height = `${card.offsetHeight}px`;
                            cloneCard.style.width = `${card.offsetWidth}px`;
                            cloneCard.style.top = `${card.getBoundingClientRect().top}px`;
                            cloneCard.style.left = `${card.getBoundingClientRect().left}px`;
                            card.insertAdjacentElement('afterend',cloneCard);

                            cloneCard.style.left = `${myCardsIcon.getBoundingClientRect().left + (cloneCard.offsetWidth / 20)}px`;
                            cloneCard.style.top = `${myCardsIcon.getBoundingClientRect().top}px`;
                            cloneCard.style.opacity = '0.1';
                            const speen = (clicks % 5 === 0) ? ' rotate(720deg)' : '';
                            cloneCard.style.transform = `scale(0.1, 0.3)${speen}`;

                            setTimeout(() => cloneCard.remove(), 800);
                        }
                    }

                    useDeck().setCard({uid: props.card.uid, competenceId: props.card.competenceId});
                }
            },
            isInDeck,
            cardClasses: computed(() => {
                const classes = [props.color];

                if (isInDeck.value) {
                    classes.push('selected');
                }

                return classes.join(' ');
            }),
            onInput(e: Event & {target: HTMLTextAreaElement}) {
                useDeck().setCard({uid: e.target.id, competenceId: props.card.competenceId, input: e.target.value})
            }
        }
    }
})
