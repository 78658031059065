
import {defineComponent} from 'vue';
import {Routes} from '@/router';
import useGroups from '@/composeables/useGroups';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import useConstants from '@/composeables/useConstants';

export default defineComponent({
    name: 'ChooseEducationTypePage',
    components: {ProgressSpinner},
    setup() {
        useGroups().load();

        return {
            groups: useGroups().groups,
            Routes,
            groupDelay: useConstants().getGroupDelay,
        }
    }
})
