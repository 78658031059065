import {ref} from 'vue';

export default class XhrResource<T> {
    private activePromise: Promise<T> | undefined;
    private lastFetchedTarget = '';
    private readonly dataRef = ref<T>();
    private readonly formatter: (data: any) => T;

    constructor(formatter?: (data: any) => T) {
        this.formatter = formatter ?? ((data) => data);
    }

    public load(target: string) {
        // If a promise is in-progress, return it
        if (this.activePromise) {
            return this.activePromise;
        }

        // If our target is the same as the previous one, just return the stored data
        if (this.lastFetchedTarget === target) {
            return Promise.resolve(this.dataRef.value);
        }

        this.lastFetchedTarget = target;

        this.activePromise = fetch(`${window.location.origin}/${target}`).then((response) => {
            return response.json();
        }).then((result) => {
            this.activePromise = undefined;
            this.dataRef.value = this.formatter(result);

            return this.dataRef.value;
        });

        return this.activePromise;
    }

    /** Returns the active promise. If no such promise exists, returns a promise that instantly resolves with the current stored data. */
    public getPromise() {
        return this.activePromise ?? Promise.resolve(this.dataRef.value);
    }

    public refresh() {
        const target = this.lastFetchedTarget;
        this.clear();
        return this.load(target);
    }

    public get() {
        return this.dataRef;
    }

    public set(newValue: T) {
        this.dataRef.value = newValue;
    }

    public clear() {
        this.activePromise = undefined;
        this.dataRef.value = undefined;
        this.lastFetchedTarget = '';
    }
}
