
import {defineComponent, PropType} from 'vue';
import {Routes} from '@/router';
import {EducationType} from '@/composeables/useGroups';

export default defineComponent({
    name: 'PreviousPageButton',
    props: {
        educationType: {type: Object as PropType<EducationType>, required: true},
        group: {type: Object, required: true},
        subgroup: {type: Object, default: undefined},
    },
    setup(props) {
        let to;
        if (props.subgroup) {
            to = {name: Routes.ChooseCompetence, params: {educationTypeId: props.educationType.id, groupId: props.group.id}};
        } else {
            to = {name: Routes.ChooseCompetenceGroup, params: {educationTypeId: props.educationType.id}};
        }

        return {to};
    }
})
