import {createRouter, createWebHashHistory, RouteLocationNormalized, RouteRecordRaw} from 'vue-router';
import MainView from '@/views/MainView.vue';
import ChooseEducationTypePage from '@/pages/ChooseEducationTypePage.vue';
import MyCardsPage from '@/pages/MyCardsPage.vue';
import ExportPage from '@/pages/ExportPage.vue';
import ChooseCompetenceGroupPage from '@/pages/ChooseCompetenceGroupPage.vue';
import ChooseCompetencePage from '@/pages/ChooseCompetencePage.vue';
import CompetencePage from '@/pages/CompetencePage.vue';
import SimplePage from "@/pages/SimplePage.vue";

// This object stores scrollpositions for each unique path
const scrollPositions: {[p: string]: {top: number, left: number}} = {};

export enum Routes {
    SelectEducationType = 'SelectEducationType',
    ChooseCompetenceGroup = 'ChooseCompetenceGroup',
    ChooseCompetence = 'ChooseCompetence',
    CompetencePage = 'CompetencePage',
    MyCards = 'MyCards',
    Export = 'Export',
    Page = 'Page'
}

const paramToInt = (input: string|string[]) => {
    if (Array.isArray(input)) {
        return input.map((item) => parseInt(item, 10))
    }

    return parseInt(input, 10);
}

const asNumbers = (loc: RouteLocationNormalized) => {
    const obj: {[key: string]: number|number[]} = {};

    for (const paramsKey in loc.params) {
        obj[paramsKey] = paramToInt(loc.params[paramsKey]);
    }

    return obj;
}

const routes: Array<RouteRecordRaw&{name?: Routes}> = [
    {path: '/', component: MainView, children: [
        {path: '', redirect: {name: Routes.SelectEducationType}},
        {path: '/page/:slug', name: Routes.Page, props: true, component: SimplePage},
        {path: '/cards', children: [
            {path: '', name: Routes.SelectEducationType, props: asNumbers, component: ChooseEducationTypePage},
            {path: '/cards/:educationTypeId', name: Routes.ChooseCompetenceGroup, props: asNumbers, component: ChooseCompetenceGroupPage},
            {path: '/cards/:educationTypeId/:groupId', name: Routes.ChooseCompetence, props: asNumbers, component: ChooseCompetencePage},
            {path: '/cards/:educationTypeId/:groupId/:variationId', name: Routes.CompetencePage, props: asNumbers, component: CompetencePage}
        ]},
        {path: '/my-cards', name: Routes.MyCards, props: asNumbers, component: MyCardsPage},
        {path: '/export', name: Routes.Export, props: asNumbers, component: ExportPage}
    ]}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        return savedPosition || scrollPositions?.[to.path] || {top: 0, left: 0};
    }
});

router.beforeEach((to, from, next) => {
    if (!from.meta.scrollPos) {
        from.meta.scrollPos = {};
    }

    scrollPositions[from.path] = Object.freeze({top: window.scrollY, left: window.scrollX});
    return next()
})

export default router;
