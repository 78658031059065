import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "cards cards--grouped cards--wide mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardBlock = _resolveComponent("CardBlock")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["breakout theme-bg has-icon", _ctx.color])
    }, [
      _createElementVNode("h5", null, [
        _createElementVNode("i", {
          class: _normalizeClass(["oif", _ctx.icon])
        }, null, 2),
        _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_TransitionGroup, {
        tag: "div",
        name: "slide-fade",
        class: _normalizeClass(["card theme-bg light", _ctx.color]),
        appear: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card, uid, j) => {
            return (_openBlock(), _createBlock(_component_CardBlock, {
              key: uid,
              card: card,
              color: _ctx.color,
              style: _normalizeStyle(_ctx.cardDelay(_ctx.index, j))
            }, null, 8, ["card", "color", "style"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["class"])
    ])
  ]))
}