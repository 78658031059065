
import {defineComponent, ref} from 'vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import FetchHelper from "@/helpers/FetchHelper";

export default defineComponent({
    name: 'SimplePage',
    components: {ProgressSpinner},
    props: {
        slug: {type: String, required: true}
    },
    setup(props) {
        const content = ref<string>();
        FetchHelper.get<any[]>(`wp-json/wp/v2/pages?slug=${props.slug}&_fields[]=acf`).then((response) => {
            content.value = response[0]?.acf?.content ?? undefined;
        });

        return {content};
    }
})
