
import {computed, defineComponent} from 'vue';
import {Routes} from '@/router';
import useGroups from '@/composeables/useGroups';
import useConstants from '@/composeables/useConstants';

export default defineComponent({
    name: 'ChooseCompetenceGroupPage',
    props: {
        educationTypeId: {type: Number, required: true}
    },
    setup(props) {
        useGroups().load();

        const educationType = computed(() => useGroups().groups.value?.educationTypes[props.educationTypeId]);
        const competenceGroups = computed(() => useGroups().groups.value?.competenceGroups);

        return {
            educationType,
            competenceGroups,
            Routes,
            cardDelay: useConstants().getCardDelay
        }
    }
})
