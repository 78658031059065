import {ref, Ref} from 'vue';

export default class LocalResource<T> {
    private readonly key;
    private readonly dataRef = ref<T>();

    constructor(key: string, initialValue: T) {
        this.key = key;
        this.set(this.parseFromStorage() ?? initialValue);
    }

    public get() {
        return this.dataRef as Ref<T>;
    }

    public set(value: T) {
        this.dataRef.value = value;
        localStorage.setItem(this.key, JSON.stringify(value));
    }

    private parseFromStorage() {
        const item = localStorage.getItem(this.key);

        if (item) {
            try {
                return JSON.parse(item);
            } catch (err) {
                console.error(err);
            }
        }

        return item;
    }
}
