import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-block mb-1 text-muted"
}
const _hoisted_2 = { class: "mb-3 pe-3" }
const _hoisted_3 = {
  key: 0,
  class: "form-control"
}
const _hoisted_4 = ["id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card theme-bg light competence-card pagebreak-inside-avoid", _ctx.cardClasses])
  }, [
    (_ctx.skillLevelLabel)
      ? (_openBlock(), _createElementBlock("small", _hoisted_1, _toDisplayString(_ctx.skillLevelLabel), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.card.text), 1),
    _createElementVNode("button", {
      type: "button",
      class: "card__button btn btn-link",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addOrRemoveCard && _ctx.addOrRemoveCard(...args)))
    }, _toDisplayString(_ctx.isInDeck ? '-' : '+'), 1),
    (_ctx.storedData && (!_ctx.isPdfExport || _ctx.storedData?.input?.trim()))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.isPdfExport)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.storedData.input), 1))
            : (_openBlock(), _createElementBlock("textarea", {
                key: 1,
                id: _ctx.card.uid,
                value: _ctx.storedData.input,
                placeholder: "mijn gedachten hierover",
                class: "form-control",
                onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
              }, null, 40, _hoisted_4))
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}