
import {defineComponent} from 'vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import useDeck from '@/composeables/useDeck';
import useCompetences from '@/composeables/useCompetences';
import MyCardsCompetenceBlock from '@/components/MyCardsCompetenceBlock.vue';
import useMyGroups, { GroupB } from '@/composeables/useMyGroups';

export default defineComponent({
    name: 'MyCardsPage',
    components: {MyCardsCompetenceBlock, ProgressSpinner},
    props: {
        isPdfExport: {type: Boolean, default: false}
    },
    setup() {
        useCompetences().load();
        useMyGroups().load();

        return {
            groups: useMyGroups().myGroups,
            competences: useCompetences().competences,
            removeGroup(groupB: GroupB) {
                const cardsToSelect = [];

                for (const b in groupB.children) {
                    const groupC = groupB.children[b];

                    for (const c of groupC.children) {
                        const competence = useCompetences().competences.value?.[String(c)];
                        if (competence) {
                            for (const cardUid of [...Object.keys(competence.thoughtCards), ...Object.keys(competence.actionCards)]) {
                                if (useDeck().hasCard(cardUid)) {
                                    cardsToSelect.push(cardUid);
                                }
                            }
                        }
                    }
                }

                useDeck().selectCards(cardsToSelect);
            }
        };
    }
})
