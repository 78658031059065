import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "choose-card-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreviousPageButton = _resolveComponent("PreviousPageButton")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.educationType && _ctx.competenceGroup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PreviousPageButton, {
          "education-type": _ctx.educationType,
          group: _ctx.competenceGroup,
          class: "mb-3 text-decoration-none"
        }, null, 8, ["education-type", "group"]),
        _createVNode(_TransitionGroup, {
          tag: "ul",
          class: "subgroups",
          name: "fade",
          appear: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.competenceGroup?.children, (id, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: id,
                class: "subgroup",
                style: _normalizeStyle(_ctx.groupDelay(i))
              }, [
                _createElementVNode("h5", {
                  class: _normalizeClass(["subgroup__title breakout theme-bg mb-0", _ctx.educationType.themeColor])
                }, _toDisplayString(_ctx.subGroups[id].name), 3),
                _createVNode(_TransitionGroup, {
                  tag: "div",
                  class: "cards cards--wide",
                  name: "slide-fade",
                  appear: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subGroups[id].children, (variationId, j) => {
                      return (_openBlock(), _createBlock(_component_RouterLink, {
                        key: variationId,
                        to: {name: _ctx.Routes.CompetencePage, params: {educationTypeId: _ctx.educationTypeId, groupId: _ctx.groupId, variationId}},
                        class: _normalizeClass(["h6 mb-0 card card--has-tab theme-bg light", _ctx.educationType.themeColor]),
                        style: _normalizeStyle(_ctx.cardDelay(i, j))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.variations[variationId].name), 1)
                        ]),
                        _: 2
                      }, 1032, ["to", "class", "style"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ], 4))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
}