import LocalResource from '@/helpers/LocalResource';
import {LocalCard} from '@/common/interfaces';
import {ref, computed} from 'vue';

const cards = new LocalResource<{[key:string]: LocalCard}>('cards', {});
const selectedCards = ref<string[]>([]);

export default () => {
    const getCardsAsObject = () => {
        const _cards = cards.get().value;
        if (_cards && typeof _cards === 'object') {
            return _cards;
        }

        return {};
    };

    const removeCard = (uid: string) => {
        const _cards = getCardsAsObject();
        delete _cards[uid];
        cards.set(_cards)
    }

    return {
        cards: cards.get(),
        selectedCards: computed(() => selectedCards.value),
        competenceIds: computed(() => {
            const cIds: number[] = [];
            const _cards = cards.get().value;

            for (const uid in _cards) {
                if (!cIds.includes(_cards[uid].competenceId)) {
                    cIds.push(_cards[uid].competenceId)
                }
            }

            return cIds;
        }),
        hasCard: (uid: string) => {
            return uid in cards.get().value;
        },
        setCard: (card: LocalCard) => {
            const _cards = getCardsAsObject();
            _cards[card.uid] = card;
            cards.set(_cards)
        },
        /** Select a group of cards by passing an array of UID's. This will replace the previous selection. */
        selectCards: (uids: string[]) => {
            selectedCards.value = uids;
            document.body.classList.toggle('modal-open', !!selectedCards.value.length);
        },
        removeSelectedCards: () => {
            for (const uid of selectedCards.value) {
                removeCard(uid);
            }

            selectedCards.value = [];
        },
        removeCard
    }
}
