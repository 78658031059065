
import {computed, defineComponent, PropType} from 'vue';
import {Competence} from '@/composeables/useCompetences';
import CardBlock from '@/components/CardBlock.vue';
import useDeck from '@/composeables/useDeck';
import {CompetenceGroup, CompetenceVariations, EducationType} from '@/composeables/useGroups';
import {LocalCard, ServerCard} from '@/common/interfaces';
import { Routes } from '@/router';

interface TypedCard {
    localCard: LocalCard;
    serverCard: ServerCard;
    skillLevelName: string;
}

export default defineComponent({
    name: 'MyCardsCompetenceBlock',
    components: {CardBlock},
    props: {
        variation: {type: Object as PropType<{term: CompetenceVariations, children: number[]}>, required: true},
        competences: {type: Object as PropType<{[key: string]: Competence}>, required: true},
        educationType: {type: Object as PropType<EducationType>, required: true},
        group: {type: Object as PropType<CompetenceGroup>, required: true},
        isPdfExport: {type: Boolean, default: false}
    },
    setup(props) {
        const cards = computed(() => {
            const deck = useDeck().cards.value;
            const actionCards: TypedCard[] = [];
            const thoughtCards: TypedCard[] = [];

            for (const id of props.variation.children) {
                const competence = props.competences[id];
                const skillLevelName = competence.skillLevel?.name || '';

                for (const i in competence.actionCards) {
                    const uid = competence.actionCards[i].uid;

                    if (uid in deck) {
                        actionCards.push({
                            localCard: deck[uid],
                            serverCard: competence.actionCards[i],
                            skillLevelName
                        });
                    }
                }

                for (const i in competence.thoughtCards) {
                    const uid = competence.thoughtCards[i].uid;

                    if (uid in deck) {
                        thoughtCards.push({
                            localCard: deck[uid],
                            serverCard: competence.thoughtCards[i],
                            skillLevelName
                        });
                    }
                }
            }

            return {actionCards, thoughtCards};
        });

        return {
            cards,
            competenceRoute: computed(() => {
                return {name: Routes.CompetencePage, params: {educationTypeId: props.educationType.id, groupId: props.group.id, variationId: props.variation.term.id}};
            })
        };
    }
})
