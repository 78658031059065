
import {computed, defineComponent} from 'vue';
import {Routes} from '@/router';
import useDeck from '@/composeables/useDeck';
import RemoveCardModal from '@/components/standalone/RemoveCardModal.vue';

export default defineComponent({
    name: 'MainView',
    components: {RemoveCardModal},
    setup() {
        return {
            selectedCards: useDeck().selectedCards,
            myCardsCount: computed(() => Object.keys(useDeck().cards.value).length),
            Routes
        };
    }
})
