import XhrResource from '@/helpers/XhrResource';
import {WpTerm} from '@/common/interfaces';

export interface EducationType extends WpTerm {
    children: number[];
    themeColor: string;
}

export interface CompetenceGroup extends WpTerm {
    parentId: number;
    children: number[];
}

export interface CompetenceSubgroup extends WpTerm {
    parentId: number;
    children: number[];
}

export interface CompetenceVariations extends WpTerm {
    parentId: number;
    competences: {[key: string]: string};
}

export interface GroupCollection {
    educationTypes: {[key: string]: EducationType};
    competenceGroups: {[key: string]: CompetenceGroup};
    competenceSubgroups: {[key: string]: CompetenceSubgroup};
    competenceVariations: {[key: string]: CompetenceVariations};
}

const groups = new XhrResource<GroupCollection>((groups) => {
    const educationTypes: {[key: string]: EducationType} = {};
    const competenceGroups: {[key: string]: CompetenceGroup} = {};
    const competenceSubgroups: {[key: string]: CompetenceSubgroup} = {};
    const competenceVariations: {[key: string]: CompetenceVariations} = {};

    // Extract EducationTypes
    for (const id in groups) {
        if (!groups[id].parentId) {
            educationTypes[id] = {...groups[id], children: []};
            delete groups[id];
        }
    }

    // Extract Groups
    for (const id in groups) {
        const parentId = groups[id].parentId;

        if (parentId in educationTypes) {
            competenceGroups[id] = {...groups[id], children: []};
            educationTypes[parentId].children.push(parseInt(id, 10));
            delete groups[id];
        }
    }

    // Extract Subgroups
    for (const id in groups) {
        const parentId = groups[id].parentId;

        if (parentId in competenceGroups) {
            competenceSubgroups[id] = {...groups[id], children: []};
            competenceGroups[parentId].children.push(parseInt(id, 10));
            delete groups[id];
        }
    }

    // Extract variations
    for (const id in groups) {
        const parentId = groups[id].parentId;

        competenceVariations[id] = groups[id];
        competenceSubgroups[parentId].children.push(parseInt(id, 10));
        delete groups[id];
    }

    return Object.freeze({educationTypes, competenceGroups, competenceSubgroups, competenceVariations});
});

export default () => {
    return {
        groups: groups.get(),
        load() {
            return groups.load(`wp-json/raow-api/competence-groups/`)
        }
    }
}
