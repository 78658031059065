import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal d-flex align-items-center",
  tabindex: "-1",
  role: "dialog",
  "aria-modal": "true"
}
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "mb-0" }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.selectedCards.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("p", _hoisted_5, "Weet u zeker dat u de deze " + _toDisplayString(_ctx.selectedCards.length > 1 ? `${_ctx.selectedCards.length} kaarten` : 'kaart') + " wilt verwijderen?", 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-light",
                    "data-bs-dismiss": "modal",
                    "aria-label": "Close",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
                  }, " Annuleer "),
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-primary",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeSelectedCards && _ctx.removeSelectedCards(...args)))
                  }, " Ja, verwijder ")
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}