import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.content !== undefined)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        innerHTML: _ctx.content
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
}